.modal-content{
    font-family: Montserrat;
}
.modal-header{
    border-bottom: none !important;
    .btn-close{
        position: absolute;
    }
}
.modal-title{
    width: 100%;
    font-weight: bold;
    text-align: center;
    color: rgb(189, 157, 96);
}
.modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    .pfp{
        width: 15em;
        height: 15em;
        border-radius: 50%;
        img{
            width: 15em;
            height: 15em;
        }
    }
}
.modal-footer{
    border-top: none !important;

}