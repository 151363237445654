@import "../../index.scss";
.main-view{
    z-index: 10;
    justify-content: flex-end;
    font-size: 1vw;
    width: 50em;
    display: flex;
    align-items: center;
    height: 95vh;
    border-radius: 7.5em 7.5em 0em 0em;
    // border-radius: 3em 3em 0em 0em;
    flex-direction: column;
    background: white;
    border-left: 0.2em solid white;
    border-right: 0.2em solid rgb(245, 245, 245);
    border-top: 0.5em solid rgb(255, 250, 247);
    box-shadow: 0em 0.2em 0.9em 0.4em rgba(0, 0, 0, 0.5);
    .logo{
        position: static;
        top: 0;
        // cursor: pointer;
        margin-top: 1em;
        margin-bottom: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Times New Roman', Times, serif;
        color: $gold;
        letter-spacing: 0.2em;
        font-size: 2vw;
        border-top: 0.07em solid $gold;
        border-bottom: 0.07em solid $gold;
        
    }
}

.closed-screen{
    height: 100%;
    top: 0;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgb(255, 255, 255);
        .top{
        height: 100%;
        width: 100%;
        animation: closeScreen 0.3s ease-out;
        background-color: black;
        display: flex;
    }
    // .bot{
    //     height: 50%;
    //     width: 100%;
    //     animation: closeScreen 0.3s ease-in;
    //     background-color: black;
    // }
    
}
.closed-screen.destroy{
    // .top{
    //     background-color: rgb(50, 10, 10);
    // }
    .top{
        padding: 2em;
        width: 100%;
        color: greenyellow;
        cursor: default;
        user-select: none;
        // align-self: center;
        // font-size: 5vw;
        // justify-self: center;
        // text-align: center;
        // animation: alertMessage 2s forwards 0s infinite;
        h1{
            position: absolute;
            white-space: pre-line;
            font-size: 1vw;
            width: 45vw;
            overflow-wrap: break-word;
        }

    }
    
}
@keyframes closeScreen {
    0%{
        height: 0%;
    }
    100%{
        height: 100%;
    }
}
.chat-field{
    background: linear-gradient(rgb(255, 249, 235) 0%, rgb(236, 218, 189));;
    border-radius: 0.5em 0.5em 0em 0em;
    width: 98%;
    padding-left: 2.5em;
    padding-right: 2.5em;
    // margin-top: 5em;
    height: 100%;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: space-between;
    .messages-field{
        display: flex;
        flex-direction: column;
        
        .typing{
            animation: typingMessage 1s ease-in;
            .message{
                span{
                    bottom: 0;
                    position: relative;
                }
                .dot-1{
                    animation: typingDots 0.75s forwards 0s infinite;
                    bottom: 0.25em;
                }
                .dot-2{
                    animation: typingDots 0.75s ease-in 0.2s infinite;
                    
                }
                .dot-3{
                    animation: typingDots 0.75s ease-in 0.4s infinite;
                    
                }
            }
        } 
    }
    
    .btn-options{
        gap: 1em;
        flex-wrap: wrap;
        position: relative;
        align-self: center;
        width: 100%;
        display: flex;
        font-size: 1.5vw;
        justify-content: space-evenly;
        margin-bottom: 0.5em;
        button{
            border: none;
            background-color: $gold;
            min-width: 5em;
            height: 1.5em;
            padding-left: 1em;
            padding-right: 1em;
            color: white;
            border-radius: 1em;
            transition: 0.3s;
            box-shadow: 0em 0.05em 0.05em 0em rgba(0,0,0,0.75);

        }
        button:hover{
            color: $gold;
            background-color: white;
            box-shadow: 0em 0.03em 0.1em 0em rgba(0,0,0,0.7);


        }
        button:active{
            color: $gold;
            background-color: white;
            transition: 0.1s;

        }
    }
}
.destruction-message{
    animation: alertMessage 1s forwards 0s infinite;

}
@keyframes alertMessage {
    0%{
        color: red;
    }
    50%{
        color: black;
    }
    100%{
        color: red;
    }
}  
@keyframes typingMessage {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}   
@keyframes typingDots {
    0%{
        bottom: 0;
    }
    50%{
        bottom: 0.2em;

    }
75%{
    bottom: 0;
}
    100%{
        bottom: 0;
    }
}
.chat-field::-webkit-scrollbar {
    display: none;
  }
@media (max-width: 990px) {
    .main-view{
        width: 100vw;
        border-radius: 0em 0em 0em 0em;
        height: 100vh;
        .logo{
            font-size: 4.5vw;
        }
    }
    .chat-field{
        .btn-options {
            font-size: 5vw;

        }
    }
    .closed-screen.destroy{
        .top{
            h1{
                font-size: 2vw !important;
                width: 90vw;
            }
        }
    }
}
.App.error{
    .main-view{
        background: rgb(15, 15, 15);
        border-left: 0.2em solid rgb(19, 19, 19);
        border-right: 0.2em solid rgb(8, 8, 8);
        border-top: 0.5em solid rgb(10, 10, 10);
    }
    .chat-field{
        background: linear-gradient(rgb(0, 0, 0) 0%,rgb(74, 0, 0));
    }
}
.App.dark{
    .main-view{
        background: rgb(15, 15, 15);
        border-left: 0.2em solid rgb(19, 19, 19);
        border-right: 0.2em solid rgb(8, 8, 8);
        border-top: 0.5em solid rgb(10, 10, 10);
    }
}