@import "../../index.scss";

.message-container{
    display: flex;
    animation: newMessage 0.3s ease-in;

}
@keyframes newMessage {
    0%{
        // transform: translateY(5em);
        opacity: 0;
    }
    100%{
        // transform: translateY(0em);
        opacity: 1;
    }
}
.message::after{
    content: attr(data-date);
    font-size: 0.7em;
    display: block;
    width: 100%;
    text-align: right;
    opacity: 0.3;
    height: 0.8em;
}
.message{
    white-space: pre-line;
    font-size: 1.1vw;
    height:fit-content;
    max-width: 16em;
    line-height: 1.2em;
    margin: 1em;
    margin-bottom: 0.5em;
    padding: 0.5em;
    
    box-shadow: 0em 0.05em 0.05em 0em rgba(0,0,0,0.3);
    .greetings-gif{
        width: 15em;
        height: 15em;
        border-radius: 1em;
    }
}
.avatar{
    font-size: 3vw;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    margin-top: 0.35em;
    box-shadow: 0em 0.02em 0.02em 0em rgba(0,0,0,0.3);

    // align-self: center;
}

.message-container.bot{
    .message{
        background-color: rgb(255, 255, 255);
        border-radius: 0em 1em 1em 1em;
    }
    .avatar{
        background-color: $gold;
        // background-image: url("https://golden-recovery.com/logo512.png");
        background-image: url("../../media/img/bot.svg");
        background-size: contain;
    }
}
.message-container.client{
    flex-direction: row-reverse;    
    .message{
        background-color: rgb(255, 255, 255);
        border-radius: 1em 0em 1em 1em;
    }
    .avatar{
        background-color: rgb(235, 217, 163);
    }
}


@media (max-width: 990px) {
    .message{
        font-size: 4vw;
    }
    .avatar{
        font-size: 12vw;
    }
}
.App.error{
    .message-container{
        animation: none;
    }
    .avatar{
        
        background-color: $error;
        // background-image: url("https://golden-recovery.com/logo512.png");
        background-image: url("../../media/img/skull.svg");
        background-size: contain;
    }
}