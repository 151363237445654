.modal-content {
  font-family: Montserrat;
}

.modal-header {
  border-bottom: none !important;
}

.modal-header .btn-close {
  position: absolute;
}

.modal-title {
  width: 100%;
  font-weight: bold;
  text-align: center;
  color: #bd9d60;
}

.modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-body .pfp {
  width: 15em;
  height: 15em;
  border-radius: 50%;
}

.modal-body .pfp img {
  width: 15em;
  height: 15em;
}

.modal-footer {
  border-top: none !important;
}
