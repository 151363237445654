html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  letter-spacing: 0.01vw;
  font-family: Helvetica !important;
  margin: 0;
  overflow-y: hidden;
  background-color: #C69B57;
}

.App {
  font-size: 1vw;
  width: 100vw;
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(188, 172, 141, 0.878)), to(rgba(225, 203, 158, 0.678)));
  background: linear-gradient(rgba(188, 172, 141, 0.878), rgba(225, 203, 158, 0.678));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.App::after {
  content: "";
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(188, 172, 141, 0.878)), to(rgba(225, 203, 158, 0.678))), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-image: linear-gradient(rgba(188, 172, 141, 0.878), rgba(225, 203, 158, 0.678)), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 0;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.App.error {
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-animation: alertScreen 1.5s infinite;
          animation: alertScreen 1.5s infinite;
}

.App.error::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#6d1616e0), to(#6d1d1dcb));
  background: linear-gradient(#6d1616e0, #6d1d1dcb);
}

.App.dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(161, 134, 79, 0.878)), to(rgba(245, 193, 88, 0.678))), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-image: linear-gradient(rgba(161, 134, 79, 0.878), rgba(245, 193, 88, 0.678)), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
}

@-webkit-keyframes alertScreen {
  0% {
    background: #651717;
  }
  50% {
    background: #9a2d2d;
  }
  100% {
    background: #651717;
  }
}

@keyframes alertScreen {
  0% {
    background: #651717;
  }
  50% {
    background: #9a2d2d;
  }
  100% {
    background: #651717;
  }
}

.toggle-dark {
  z-index: 5;
}

.message-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: newMessage 0.3s ease-in;
          animation: newMessage 0.3s ease-in;
}

@-webkit-keyframes newMessage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes newMessage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message::after {
  content: attr(data-date);
  font-size: 0.7em;
  display: block;
  width: 100%;
  text-align: right;
  opacity: 0.3;
  height: 0.8em;
}

.message {
  white-space: pre-line;
  font-size: 1.1vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 16em;
  line-height: 1.2em;
  margin: 1em;
  margin-bottom: 0.5em;
  padding: 0.5em;
  -webkit-box-shadow: 0em 0.05em 0.05em 0em rgba(0, 0, 0, 0.3);
          box-shadow: 0em 0.05em 0.05em 0em rgba(0, 0, 0, 0.3);
}

.message .greetings-gif {
  width: 15em;
  height: 15em;
  border-radius: 1em;
}

.avatar {
  font-size: 3vw;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-top: 0.35em;
  -webkit-box-shadow: 0em 0.02em 0.02em 0em rgba(0, 0, 0, 0.3);
          box-shadow: 0em 0.02em 0.02em 0em rgba(0, 0, 0, 0.3);
}

.message-container.bot .message {
  background-color: white;
  border-radius: 0em 1em 1em 1em;
}

.message-container.bot .avatar {
  background-color: #C69B57;
  background-image: url("../../media/img/bot.svg");
  background-size: contain;
}

.message-container.client {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.message-container.client .message {
  background-color: white;
  border-radius: 1em 0em 1em 1em;
}

.message-container.client .avatar {
  background-color: #ebd9a3;
}

@media (max-width: 990px) {
  .message {
    font-size: 4vw;
  }
  .avatar {
    font-size: 12vw;
  }
}

.App.error .message-container {
  -webkit-animation: none;
          animation: none;
}

.App.error .avatar {
  background-color: #651717;
  background-image: url("../../media/img/skull.svg");
  background-size: contain;
}
