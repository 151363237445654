$gradient: linear-gradient(rgba(161, 134, 79, 0.878),rgba(245, 193, 88, 0.678));
$gold: #C69B57;
$gradientError: linear-gradient(#6d1616e0,#6d1d1dcb);
$error: #651717;
$gradientLight:linear-gradient(rgba(188, 172, 141, 0.878),rgba(225, 203, 158, 0.678));
html {
    box-sizing: border-box;
}
body{
    letter-spacing: 0.01vw;
    font-family: Helvetica !important;
    margin: 0;
    overflow-y: hidden;
    background-color: $gold;
}
.App{
    font-size: 1vw;
    width: 100vw;
    height: 100vh;
    background: $gradientLight;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    transition: 0.3s;
}
.App::after{
    content: "";
    background-image: $gradientLight, url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    mix-blend-mode: multiply;
    position: absolute;
    z-index: 0;
    filter: opacity(0.5);
    transition: 0.3s;
}
.App.error{
    // background: $gradientError;
    transition: 1s;
    animation: alertScreen 1.5s infinite;

}
.App.error::after{
    background: $gradientError;


}
.App.dark{
    background-image: $gradient, url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");

}
@keyframes alertScreen {
    0%{
        background: $error;
    }
    50%{
        background: #9a2d2d;
    }
    100%{
        background: $error;
    }
}
.App.dark::after{
    // background-image: $gradientDark, url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");

}
.toggle-dark{
    z-index: 5;
}