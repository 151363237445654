.text-field {
  position: static;
  bottom: 0;
  width: 98%;
  font-size: 1.1vw;
  height: 5em;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.text-field input {
  width: 100%;
  border: none;
  height: 90%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding-left: 0.5em;
}

.text-field input:focus {
  outline: none;
}

.text-field button {
  background-color: transparent;
  padding-right: 0.5em;
  border: none;
}

.text-field .fas {
  opacity: 0.5;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.text-field button:hover .fas {
  -webkit-transform: translate(0.25em, -0.25em);
          transform: translate(0.25em, -0.25em);
  opacity: 0.1;
}

.text-field button:disabled {
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 990px) {
  .text-field {
    font-size: 4.5vw;
  }
}
