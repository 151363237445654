html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  letter-spacing: 0.01vw;
  font-family: Helvetica !important;
  margin: 0;
  overflow-y: hidden;
  background-color: #C69B57;
}

.App {
  font-size: 1vw;
  width: 100vw;
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(188, 172, 141, 0.878)), to(rgba(225, 203, 158, 0.678)));
  background: linear-gradient(rgba(188, 172, 141, 0.878), rgba(225, 203, 158, 0.678));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.App::after {
  content: "";
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(188, 172, 141, 0.878)), to(rgba(225, 203, 158, 0.678))), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-image: linear-gradient(rgba(188, 172, 141, 0.878), rgba(225, 203, 158, 0.678)), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 0;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.App.error {
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-animation: alertScreen 1.5s infinite;
          animation: alertScreen 1.5s infinite;
}

.App.error::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#6d1616e0), to(#6d1d1dcb));
  background: linear-gradient(#6d1616e0, #6d1d1dcb);
}

.App.dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(161, 134, 79, 0.878)), to(rgba(245, 193, 88, 0.678))), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
  background-image: linear-gradient(rgba(161, 134, 79, 0.878), rgba(245, 193, 88, 0.678)), url("https://lp1.golden-recovery.com/static/media/city.fed3a3b70ec9a2101ec5.jpg");
}

@-webkit-keyframes alertScreen {
  0% {
    background: #651717;
  }
  50% {
    background: #9a2d2d;
  }
  100% {
    background: #651717;
  }
}

@keyframes alertScreen {
  0% {
    background: #651717;
  }
  50% {
    background: #9a2d2d;
  }
  100% {
    background: #651717;
  }
}

.toggle-dark {
  z-index: 5;
}

.main-view {
  z-index: 10;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 1vw;
  width: 50em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 95vh;
  border-radius: 7.5em 7.5em 0em 0em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: white;
  border-left: 0.2em solid white;
  border-right: 0.2em solid whitesmoke;
  border-top: 0.5em solid #fffaf7;
  -webkit-box-shadow: 0em 0.2em 0.9em 0.4em rgba(0, 0, 0, 0.5);
          box-shadow: 0em 0.2em 0.9em 0.4em rgba(0, 0, 0, 0.5);
}

.main-view .logo {
  position: static;
  top: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Times New Roman', Times, serif;
  color: #C69B57;
  letter-spacing: 0.2em;
  font-size: 2vw;
  border-top: 0.07em solid #C69B57;
  border-bottom: 0.07em solid #C69B57;
}

.closed-screen {
  height: 100%;
  top: 0;
  width: 98%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background-color: white;
}

.closed-screen .top {
  height: 100%;
  width: 100%;
  -webkit-animation: closeScreen 0.3s ease-out;
          animation: closeScreen 0.3s ease-out;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.closed-screen.destroy .top {
  padding: 2em;
  width: 100%;
  color: greenyellow;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.closed-screen.destroy .top h1 {
  position: absolute;
  white-space: pre-line;
  font-size: 1vw;
  width: 45vw;
  overflow-wrap: break-word;
}

@-webkit-keyframes closeScreen {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes closeScreen {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.chat-field {
  background: -webkit-gradient(linear, left top, left bottom, from(#fff9eb), to(#ecdabd));
  background: linear-gradient(#fff9eb 0%, #ecdabd);
  border-radius: 0.5em 0.5em 0em 0em;
  width: 98%;
  padding-left: 2.5em;
  padding-right: 2.5em;
  height: 100%;
  padding-top: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.chat-field .messages-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.chat-field .messages-field .typing {
  -webkit-animation: typingMessage 1s ease-in;
          animation: typingMessage 1s ease-in;
}

.chat-field .messages-field .typing .message span {
  bottom: 0;
  position: relative;
}

.chat-field .messages-field .typing .message .dot-1 {
  -webkit-animation: typingDots 0.75s forwards 0s infinite;
          animation: typingDots 0.75s forwards 0s infinite;
  bottom: 0.25em;
}

.chat-field .messages-field .typing .message .dot-2 {
  -webkit-animation: typingDots 0.75s ease-in 0.2s infinite;
          animation: typingDots 0.75s ease-in 0.2s infinite;
}

.chat-field .messages-field .typing .message .dot-3 {
  -webkit-animation: typingDots 0.75s ease-in 0.4s infinite;
          animation: typingDots 0.75s ease-in 0.4s infinite;
}

.chat-field .btn-options {
  gap: 1em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  -ms-flex-item-align: center;
      align-self: center;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5vw;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 0.5em;
}

.chat-field .btn-options button {
  border: none;
  background-color: #C69B57;
  min-width: 5em;
  height: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  color: white;
  border-radius: 1em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0em 0.05em 0.05em 0em rgba(0, 0, 0, 0.75);
          box-shadow: 0em 0.05em 0.05em 0em rgba(0, 0, 0, 0.75);
}

.chat-field .btn-options button:hover {
  color: #C69B57;
  background-color: white;
  -webkit-box-shadow: 0em 0.03em 0.1em 0em rgba(0, 0, 0, 0.7);
          box-shadow: 0em 0.03em 0.1em 0em rgba(0, 0, 0, 0.7);
}

.chat-field .btn-options button:active {
  color: #C69B57;
  background-color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.destruction-message {
  -webkit-animation: alertMessage 1s forwards 0s infinite;
          animation: alertMessage 1s forwards 0s infinite;
}

@-webkit-keyframes alertMessage {
  0% {
    color: red;
  }
  50% {
    color: black;
  }
  100% {
    color: red;
  }
}

@keyframes alertMessage {
  0% {
    color: red;
  }
  50% {
    color: black;
  }
  100% {
    color: red;
  }
}

@-webkit-keyframes typingMessage {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes typingMessage {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes typingDots {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 0.2em;
  }
  75% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}

@keyframes typingDots {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 0.2em;
  }
  75% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}

.chat-field::-webkit-scrollbar {
  display: none;
}

@media (max-width: 990px) {
  .main-view {
    width: 100vw;
    border-radius: 0em 0em 0em 0em;
    height: 100vh;
  }
  .main-view .logo {
    font-size: 4.5vw;
  }
  .chat-field .btn-options {
    font-size: 5vw;
  }
  .closed-screen.destroy .top h1 {
    font-size: 2vw !important;
    width: 90vw;
  }
}

.App.error .main-view {
  background: #0f0f0f;
  border-left: 0.2em solid #131313;
  border-right: 0.2em solid #080808;
  border-top: 0.5em solid #0a0a0a;
}

.App.error .chat-field {
  background: -webkit-gradient(linear, left top, left bottom, from(black), to(#4a0000));
  background: linear-gradient(black 0%, #4a0000);
}

.App.dark .main-view {
  background: #0f0f0f;
  border-left: 0.2em solid #131313;
  border-right: 0.2em solid #080808;
  border-top: 0.5em solid #0a0a0a;
}
