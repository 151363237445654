.text-field{
    position: static;
    bottom: 0;
    
    width: 98%;
    font-size: 1.1vw;
    height: 5em;
    background-color: white;
    display: flex;
    input{
        width: 100%;
        border: none;
        height: 90%;
        align-self: center;
        padding-left: 0.5em;
    }
    input:focus{
        outline: none;

    }
    button{
        background-color: transparent;
        padding-right: 0.5em;
        border: none;
    }
    .fas{
        opacity: 0.5;
        transition: 0.3s;
    }
    button:hover .fas{
        transform: translate(0.25em, -0.25em);
        opacity: 0.1;
    }
    button:disabled{
        pointer-events: none;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media (max-width: 990px) {
    .text-field{
        font-size: 4.5vw;
    }
}